@use "breakpoints";
@use "variables";

// Divider between sections
div.divider {
  height: 2rem;
  width: 100%;

  @include breakpoints.sm {
    height: 4rem;
  }
}

// Hero image
section.landing {
  position: relative;

  img.hero {
    width: 100%;
    max-height: 100vh;
    min-height: 50vh;
    object-fit: cover;
    object-position: 10%;
  }
}

// Copy
section.cta {
  @include breakpoints.container();

  text-align: center;

  h2 {
    font-size: variables.$font-lg;
    margin-bottom: 1.5rem;
    font-weight: 300;

    @include breakpoints.md {
      font-size: variables.$font-xl;
    }
  }

  p {
    font-size: variables.$font-xs;
    line-height: 1.5rem;

    @include breakpoints.md {
      font-size: variables.$font-sm;
    }
  }
}

section.range-table {
  @include breakpoints.container();

  p {
    line-height: 1.5rem;
    font-weight: 300;
    text-align: left;
    font-size: variables.$font-sm;
  }

  h2 {
    font-size: variables.$font-md;
    margin-bottom: 1rem;
    margin-top: 2rem;
    font-weight: normal;
    text-align: center;

    @include breakpoints.md {
      font-size: variables.$font-lg;
    }
  }

  table {
    width: 100%;
    border-collapse: true;
  }

  td,
  th {
    padding: 0.5rem 1rem;
    border: 1px solid variables.$grey-light;
  }

  thead tr {
    background-color: variables.$grey-extra-light;
  }

  tr:nth-child(even) {
    background-color: variables.$grey-extra-light;
  }
}

section.disclaimer {
  @include breakpoints.container();
  line-height: 1.5rem;
  font-weight: 300;
  text-align: left;
  font-size: variables.$font-sm;
}

a.down-button {
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  border: 2px solid variables.$grey-extra-dark;
  margin: auto;
  cursor: pointer;
  background-color: white;
  touch-action: manipulation;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

  path {
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    box-shadow: variables.$focus-shadow;
    outline: 2px solid variables.$blue !important;
  }

  &:active {
    background-color: variables.$grey-extra-dark;
    transform: scale(0.99);
    transition: none;

    path {
      transition: none;
      color: white;
    }
  }
}
