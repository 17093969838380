// Breakpoints and container widths shamelessly stolen from Bootstrap

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

$container-width-xs: 100%;
$container-width-sm: 540px;
$container-width-md: 720px;
$container-width-lg: 960px;
$container-width-xl: 1140px;
$container-width-xxl: 1320px;

@mixin container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

  @include sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@mixin container {
  @include container-fluid();

  max-width: $container-width-xs;

  @include sm {
    max-width: $container-width-sm;
  }

  @include md {
    max-width: $container-width-sm;
  }

  @include lg {
    max-width: $container-width-md;
  }

  @include xl {
    max-width: $container-width-xl;
  }

  @include xxl {
    max-width: $container-width-xl;
  }
}
