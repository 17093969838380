@use "breakpoints";
@use "variables";

footer {
  background-color: variables.$grey-extra-dark;
  padding-top: 16px;
  padding-bottom: 16px;

  @include breakpoints.sm {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .wrapper {
    @include breakpoints.container();

    flex-wrap: wrap;
    display: flex;
    flex-direction: column;

    @include breakpoints.sm {
      flex-direction: row;
    }
  }

  div.col {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoints.sm {
      width: 50%;
    }

    @include breakpoints.lg {
      flex: 1 1 0;
    }
  }

  div.subscribe {
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    h5 {
      font-size: 14px;
    }
  }

  h2.footer-title {
    font-size: 10px;
    font-weight: bold;
    color: white;
    margin-bottom: 24px;
    margin-top: 24px;
    margin: 24px 0px;
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif;
  }

  a {
    display: inline;
    width: fit-content;
    font-size: 14px;
    color: white;
    margin-bottom: 16px;
    text-decoration: none;
    border-radius: 2px;
    padding: 2px;
    outline: 1px solid transparent;
    transition: outline-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    // Underline under headings
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 1px;
      background: white;
      transition: width 0.2s;

      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }

    // Animate underline in
    &:hover::after {
      width: 100%;
    }

    &:active {
      background-color: white;
      color: black;
    }

    &:focus {
      outline: 1px solid white;
    }
  }

  .subscribe {
    span.text {
      color: variables.$blue-light;
      font-size: 14px;
      margin-bottom: 16px;
    }

    div.email {
      display: flex;
      flex-direction: column;

      @include breakpoints.lg {
        flex-direction: row;
      }

      input.email-address {
        background-color: transparent;
        border: 1px solid variables.$blue-light;
        border-radius: 4px;
        font-weight: 300;
        padding: 1rem 1.5rem;
        color: white;
        font-size: 14px;
        flex-grow: 1;

        &::placeholder {
          color: variables.$grey-light;
        }

        &:focus {
          border: 1px solid white;
        }

        @include breakpoints.lg {
          max-width: 160px;
        }
      }

      button.subscribe {
        background-color: white;
        color: variables.$blue-dark;
        border-radius: 4px;
        padding: 16px 0px;
        outline: none;
        border: none;
        cursor: pointer;
        margin-top: 16px;
        flex-grow: 1;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.1s ease-in-out;

        &:hover {
          box-shadow: 0px 0px 16px rgba(white, 0.4);
        }

        &:focus {
          outline: 2px solid variables.$blue;
        }

        &:active {
          transition: none;
          background-color: variables.$blue;
          color: white;
        }

        @include breakpoints.lg {
          margin-top: 0px;
          margin-left: 8px;
          max-width: 120px;
        }
      }
    }
  }
}
