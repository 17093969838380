@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,700;1,400&display=swap";
* {
  color: #121212;
  scroll-behavior: smooth;
  margin: 0;
  font-family: Montserrat, sans-serif;
}

nav {
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
}

nav .navbar {
  height: 4rem;
  opacity: .95;
  background-color: #121212;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

nav .navbar img.logo {
  height: 25%;
  margin: auto;
}

nav .navbar button.hamburger {
  height: 100%;
  cursor: pointer;
  color: #fff;
  touch-action: manipulation;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  transition: transform .2s ease-in-out;
  position: absolute;
  right: 1rem;
}

nav .navbar button.hamburger:hover {
  transform: scale(1.1);
}

@media (min-width: 576px) {
  nav .navbar button.hamburger {
    display: none;
  }
}

nav div.models {
  max-width: 100%;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-left: 16px;
  padding-right: 16px;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 576px) {
  nav div.models {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  nav div.models {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  nav div.models {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  nav div.models {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  nav div.models {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  nav div.models {
    max-width: 1140px;
  }
}

nav a.model {
  color: #fff;
  letter-spacing: 2px;
  text-shadow: 1px 1px 10px #000;
  outline: none;
  padding-bottom: 10px;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  transition: transform .1s ease-in-out;
}

@media (min-width: 576px) {
  nav a.model {
    font-size: .75rem;
  }

  nav a.model:after {
    content: "";
    width: 0;
    height: 2px;
    background: #fff;
    margin-top: .5rem;
    transition: width .2s ease-in-out;
    display: block;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  nav a.model:after {
    transition: none;
  }
}

nav a.model:hover:after {
  width: 100%;
}

nav a.model:not(.selected):focus:after {
  width: 100%;
  opacity: .5;
}

nav a.model.selected:after {
  width: 100%;
}

nav a.model:not(.selected) {
  display: none;
}

@media (min-width: 576px) {
  nav a.model:not(.selected) {
    display: block;
  }
}

div.divider {
  height: 2rem;
  width: 100%;
}

@media (min-width: 576px) {
  div.divider {
    height: 4rem;
  }
}

section.landing {
  position: relative;
}

section.landing img.hero {
  width: 100%;
  max-height: 100vh;
  min-height: 50vh;
  object-fit: cover;
  object-position: 10%;
}

section.cta {
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 576px) {
  section.cta {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  section.cta {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  section.cta {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  section.cta {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  section.cta {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  section.cta {
    max-width: 1140px;
  }
}

section.cta h2 {
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  font-weight: 300;
}

@media (min-width: 768px) {
  section.cta h2 {
    font-size: 4rem;
  }
}

section.cta p {
  font-size: .75rem;
  line-height: 1.5rem;
}

@media (min-width: 768px) {
  section.cta p {
    font-size: 1rem;
  }
}

section.range-table {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 576px) {
  section.range-table {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  section.range-table {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  section.range-table {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  section.range-table {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  section.range-table {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  section.range-table {
    max-width: 1140px;
  }
}

section.range-table p {
  text-align: left;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

section.range-table h2 {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: normal;
}

@media (min-width: 768px) {
  section.range-table h2 {
    font-size: 2.5rem;
  }
}

section.range-table table {
  width: 100%;
  border-collapse: true;
}

section.range-table td, section.range-table th {
  border: 1px solid #e3e8f0;
  padding: .5rem 1rem;
}

section.range-table thead tr, section.range-table tr:nth-child(2n) {
  background-color: #f0f2f7;
}

section.disclaimer {
  max-width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

@media (min-width: 576px) {
  section.disclaimer {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  section.disclaimer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  section.disclaimer {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  section.disclaimer {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  section.disclaimer {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  section.disclaimer {
    max-width: 1140px;
  }
}

a.down-button {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  touch-action: manipulation;
  background-color: #fff;
  border: 2px solid #121212;
  border-radius: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out, transform .2s ease-in-out;
  display: -ms-flexbox;
  display: flex;
}

a.down-button path {
  transition: color .2s ease-in-out;
}

a.down-button:hover {
  transform: scale(1.05);
}

a.down-button:focus {
  box-shadow: 0 6px 24px rgba(0, 141, 255, .15), 0 0 0 1px rgba(0, 141, 255, .5);
  outline: 2px solid #008dff !important;
}

a.down-button:active {
  background-color: #121212;
  transition: none;
  transform: scale(.99);
}

a.down-button:active path {
  color: #fff;
  transition: none;
}

section.simulation h2 {
  max-width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 2rem;
  font-weight: normal;
}

@media (min-width: 576px) {
  section.simulation h2 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  section.simulation h2 {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  section.simulation h2 {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  section.simulation h2 {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  section.simulation h2 {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  section.simulation h2 {
    max-width: 1140px;
  }
}

@media (min-width: 768px) {
  section.simulation h2 {
    font-size: 2.5rem;
  }
}

section.simulation div.car {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 16px;
  position: relative;
}

@media (min-width: 576px) {
  section.simulation div.car {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  section.simulation div.car {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  section.simulation div.car {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  section.simulation div.car {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  section.simulation div.car {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  section.simulation div.car {
    max-width: 1140px;
  }
}

section.simulation div.car img {
  width: 100%;
  height: auto;
}

section.simulation div.car .car-wheel-wrapper {
  width: 8%;
  height: auto;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  transition: transform .2s ease-in-out;
  animation: spin .8s linear infinite;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 52%;
}

@media (prefers-reduced-motion: reduce) {
  section.simulation div.car .car-wheel-wrapper {
    animation: none;
  }
}

section.simulation div.car .car-wheel-wrapper.first {
  left: 26%;
}

section.simulation div.car .car-wheel-wrapper.second {
  right: 28%;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

section.simulation div.car .car-wheel-wrapper .car-wheel {
  width: 100%;
  height: auto;
  margin: auto;
  transition: transform .1s ease-in-out;
}

section.simulation div.car .car-wheel-wrapper .car-wheel.size-21 {
  transform: scale(1.1);
}

section.simulation .models {
  max-width: 100%;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 576px) {
  section.simulation .models {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  section.simulation .models {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  section.simulation .models {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  section.simulation .models {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  section.simulation .models {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  section.simulation .models {
    max-width: 1140px;
  }
}

section.simulation .models .model {
  margin: .5rem;
}

section.simulation .models .model img {
  height: 24px;
}

@media (min-width: 576px) {
  section.simulation .models .model img {
    width: 100%;
  }
}

section.simulation .range {
  color: #008dff;
  letter-spacing: 2px;
  text-align: center;
  margin-top: .5rem;
  font-size: 2rem;
  font-weight: 400;
}

@media (min-width: 576px) {
  section.simulation .range {
    letter-spacing: 6px;
    font-size: 2.5rem;
  }
}

section.simulation .subscript {
  letter-spacing: 2px;
  color: #1876c4;
  vertical-align: super;
  font-size: .75rem;
  font-weight: 300;
}

@media (min-width: 576px) {
  section.simulation .subscript {
    font-size: 1rem;
  }
}

section.settings {
  max-width: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 576px) {
  section.settings {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  section.settings {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  section.settings {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  section.settings {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  section.settings {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  section.settings {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) {
  section.settings {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

section.settings div.variables {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 2;
  flex: 2;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  section.settings div.variables {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

section.settings div.variables div.variable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 1rem;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  section.settings div.variables div.variable {
    margin-top: 0;
  }

  section.settings div.variables div.variable:first-child {
    padding-right: 1.5rem;
  }

  section.settings div.variables div.variable:last-child {
    padding-left: 1.5rem;
  }
}

section.settings .aircon {
  height: 8rem;
  width: 8rem;
  cursor: pointer;
  touch-action: manipulation;
  background-color: #f0f2f7;
  border: 1px solid #e3e8f0;
  border-radius: 50%;
  outline: none;
  -ms-flex-order: -1;
  order: -1;
  margin: 1.5rem auto;
  transition: background-color .1s ease-in-out, box-shadow .2s ease-in-out;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 1200px) {
  section.settings .aircon {
    -ms-flex-order: 0;
    order: 0;
    margin: auto 1.5rem;
  }
}

section.settings .aircon:hover {
  background-color: #e3e8f0;
}

section.settings .aircon:focus {
  box-shadow: 0 6px 24px rgba(0, 141, 255, .15), 0 0 0 1px rgba(0, 141, 255, .5);
}

section.settings .aircon:active {
  transition: none;
  transform: scale(.98);
}

section.settings .aircon .inner {
  height: 6.5rem;
  width: 6.5rem;
  background-color: #fff;
  border-radius: 50%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  transition: background-color .1s ease-in-out;
  display: -ms-flexbox;
  display: flex;
}

section.settings .aircon .inner img {
  width: auto;
  height: auto;
  margin-top: .5rem;
}

section.settings .aircon .inner span {
  color: #b9bed1;
  font-size: .75rem;
  font-weight: 300;
  transition: color .1s ease-in-out;
}

section.settings .bordered {
  border-left: none;
}

@media (min-width: 1200px) {
  section.settings .bordered {
    border-left: 1px solid #b9bed1;
  }
}

section.settings label {
  color: #707070;
  letter-spacing: 2px;
  font-size: 1.25rem;
  font-weight: 300;
  display: block;
}

section.settings div.input {
  height: 5rem;
  background-color: #f0f2f7;
  border: 1px solid #b9bed1;
  outline: 1px solid rgba(0, 0, 0, 0);
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-top: 1.5rem;
  transition: box-shadow .2s ease-in-out;
  display: -ms-flexbox;
  display: flex;
}

section.settings div.input:focus-within {
  box-shadow: 0 6px 24px rgba(0, 141, 255, .15), 0 0 0 1px rgba(0, 141, 255, .5);
}

section.settings div.input input {
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  margin: 1rem 4px;
  font-size: 2rem;
}

section.settings div.input input::-webkit-inner-spin-button, section.settings div.input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: 0;
}

section.settings div.input span.units {
  letter-spacing: 2px;
  text-transform: uppercase;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin: 1.5rem 8px;
  font-size: 20px;
}

section.settings div.input .buttons {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: -ms-flexbox;
  display: flex;
}

section.settings div.input .buttons button {
  cursor: pointer;
  height: 50%;
  touch-action: manipulation;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: 1px solid rgba(0, 0, 0, 0);
  padding-left: .5rem;
  padding-right: .5rem;
  transition: box-shadow .2s ease-in-out, outline-color .2s ease-in-out;
  display: block;
}

section.settings div.input .buttons button:hover {
  background-color: #e3e8f0;
}

section.settings div.input .buttons button:focus {
  box-shadow: 0 6px 24px rgba(0, 141, 255, .15), 0 0 0 1px rgba(0, 141, 255, .5);
}

section.settings div.input .buttons button:active {
  background-color: #b9bed1;
  transition: none;
}

section.settings .wheels {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 2;
  flex: 2;
  margin-top: 1.5rem;
  padding-left: 0;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 1200px) {
  section.settings .wheels {
    margin-top: 0;
    padding-top: 0;
    padding-left: 1.5rem;
  }
}

section.settings .wheels .wheel-options {
  height: 100%;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-top: 1.5rem;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 576px) {
  section.settings .wheels .wheel-options {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

section.settings .wheels button {
  border-radius: none;
  letter-spacing: 2px;
  width: 100%;
  height: 5rem;
  cursor: pointer;
  background-color: #f0f2f7;
  border: 1px solid #b9bed1;
  outline: 1px solid rgba(0, 0, 0, 0);
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 2rem;
  transition: background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  display: -ms-flexbox;
  display: flex;
}

section.settings .wheels button svg {
  height: 70%;
  margin-right: 1rem;
  transition: transform .2s ease-in-out;
}

section.settings .wheels button svg.wheel-icon-large {
  height: 85%;
}

section.settings .wheels button svg path {
  color: #ccc;
  transition: color .2s ease-in-out;
}

section.settings .wheels button:hover {
  background-color: #e3e8f0;
}

section.settings .wheels button:hover svg {
  transform: scale(1.1);
}

section.settings .wheels button:focus {
  box-shadow: 0 6px 24px rgba(0, 141, 255, .15), 0 0 0 1px rgba(0, 141, 255, .5);
}

section.settings .wheels button:active {
  background-color: #b9bed1;
  transition: none;
}

section.settings .wheels button:active path {
  color: #e3e8f0;
}

section.settings .wheels button.active {
  border: 1px solid #008dff;
}

section.settings .wheels button:last-child {
  margin-top: 1.5rem;
}

@media (min-width: 576px) {
  section.settings .wheels button:first-child {
    margin-right: 1.5rem;
  }

  section.settings .wheels button:last-child {
    margin-top: 0;
    margin-left: 1.5rem;
  }
}

footer {
  background-color: #121212;
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (min-width: 576px) {
  footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

footer .wrapper {
  max-width: 100%;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 576px) {
  footer .wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 576px) {
  footer .wrapper {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  footer .wrapper {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  footer .wrapper {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  footer .wrapper {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  footer .wrapper {
    max-width: 1140px;
  }
}

@media (min-width: 576px) {
  footer .wrapper {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

footer div.col {
  width: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 576px) {
  footer div.col {
    width: 50%;
  }
}

@media (min-width: 992px) {
  footer div.col {
    -ms-flex: 1;
    flex: 1;
  }
}

footer div.subscribe {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 2;
  flex-grow: 2;
  display: -ms-flexbox;
  display: flex;
}

footer div.subscribe h5 {
  font-size: 14px;
}

footer h2.footer-title {
  color: #fff;
  letter-spacing: 1px;
  margin: 24px 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: bold;
}

footer a {
  width: fit-content;
  color: #fff;
  border-radius: 2px;
  outline: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 16px;
  padding: 2px;
  font-size: 14px;
  text-decoration: none;
  transition: outline-color .2s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out;
  display: inline;
}

footer a:after {
  content: "";
  width: 0;
  height: 1px;
  background: #fff;
  transition: width .2s;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  footer a:after {
    transition: none;
  }
}

footer a:hover:after {
  width: 100%;
}

footer a:active {
  color: #000;
  background-color: #fff;
}

footer a:focus {
  outline: 1px solid #fff;
}

footer .subscribe span.text {
  color: #7fc4fd;
  margin-bottom: 16px;
  font-size: 14px;
}

footer .subscribe div.email {
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 992px) {
  footer .subscribe div.email {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

footer .subscribe div.email input.email-address {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #7fc4fd;
  border-radius: 4px;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 1rem 1.5rem;
  font-size: 14px;
  font-weight: 300;
}

footer .subscribe div.email input.email-address::-ms-input-placeholder {
  color: #e3e8f0;
}

footer .subscribe div.email input.email-address::placeholder {
  color: #e3e8f0;
}

footer .subscribe div.email input.email-address:focus {
  border: 1px solid #fff;
}

@media (min-width: 992px) {
  footer .subscribe div.email input.email-address {
    max-width: 160px;
  }
}

footer .subscribe div.email button.subscribe {
  color: #1876c4;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-top: 16px;
  padding: 16px 0;
  transition: background-color .2s ease-in-out, color .2s ease-in-out, box-shadow .1s ease-in-out;
}

footer .subscribe div.email button.subscribe:hover {
  box-shadow: 0 0 16px rgba(255, 255, 255, .4);
}

footer .subscribe div.email button.subscribe:focus {
  outline: 2px solid #008dff;
}

footer .subscribe div.email button.subscribe:active {
  color: #fff;
  background-color: #008dff;
  transition: none;
}

@media (min-width: 992px) {
  footer .subscribe div.email button.subscribe {
    max-width: 120px;
    margin-top: 0;
    margin-left: 8px;
  }
}

/*# sourceMappingURL=index.8b51083a.css.map */
