@use "breakpoints";
@use "variables";

nav {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  .navbar {
    height: 4rem;
    background-color: variables.$grey-extra-dark;
    opacity: 0.95;
    display: flex;
    position: relative;

    img.logo {
      height: 25%;
      margin: auto auto;
    }

    // Hamburger menu, only shown on mobile
    // Non-functional
    button.hamburger {
      position: absolute;
      right: 1rem;
      height: 100%;
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      color: white;
      transition: transform 0.2s ease-in-out;
      touch-action: manipulation;

      &:hover {
        transform: scale(1.1);
      }

      @include breakpoints.sm {
        display: none;
      }
    }
  }

  div.models {
    @include breakpoints.container();

    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  a.model {
    font-weight: bold;
    color: white;
    letter-spacing: 2px;
    text-decoration: none;
    font-size: variables.$font-md;
    padding-bottom: 10px;
    outline: none;
    transition: transform 0.1s ease-in-out;

    // make text more visible on white background
    text-shadow: 1px 1px 10px black;

    // Decrease font size on larger screen sizes
    @include breakpoints.sm {
      font-size: variables.$font-xs;

      // Underline under headings
      &::after {
        content: "";
        margin-top: 0.5rem;
        display: block;
        width: 0;
        height: 2px;
        background: white;
        transition: width 0.2s ease-in-out;

        @media (prefers-reduced-motion: reduce) {
          transition: none;
        }
      }
    }

    // Animate underline in
    &:hover::after {
      width: 100%;
    }

    &:not(.selected):focus::after {
      width: 100%;
      opacity: 0.5;
    }

    // Add underline to selected heading
    &.selected::after {
      width: 100%;
    }

    &:not(.selected) {
      display: none;
      @include breakpoints.sm {
        display: block;
      }
    }
  }
}
