@use "breakpoints.scss";
@use "variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,700;1,400&display=swap");

// Let's reset
* {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: variables.$grey-extra-dark;
  scroll-behavior: smooth;
}

@import "nav.scss";
@import "sections.scss";
@import "simulation.scss";
@import "footer.scss";
