@use "breakpoints";
@use "variables";

// Contains the car and range estimations
section.simulation {
  h2 {
    @include breakpoints.container();
    font-size: variables.$font-md;
    margin-bottom: 1rem;
    font-weight: normal;
    text-align: center;

    @include breakpoints.md {
      font-size: variables.$font-lg;
    }
  }

  div.car {
    @include breakpoints.container();
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;

    img {
      width: 100%;
      height: auto;
    }

    .car-wheel-wrapper {
      position: absolute;
      top: 52%;
      transition: transform 0.2s ease-in-out;
      width: 8%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: spin 0.8s linear infinite;

      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }

      &.first {
        left: 26%;
      }

      &.second {
        right: 28%;
      }

      @keyframes spin {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0deg);
        }
      }

      .car-wheel {
        transition: transform 0.1s ease-in-out;
        width: 100%;
        height: auto;
        margin: auto auto;

        &.size-21 {
          transform: scale(1.1);
        }
      }
    }
  }

  .models {
    @include breakpoints.container();
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    .model {
      margin: 0.5rem;

      img {
        height: 24px;

        @include breakpoints.sm {
          width: 100%;
        }
      }
    }
  }

  .range {
    color: variables.$blue;
    font-size: variables.$font-md;
    letter-spacing: 2px;
    font-weight: 400;
    text-align: center;
    margin-top: 0.5rem;

    @include breakpoints.sm {
      font-size: variables.$font-lg;
      letter-spacing: 6px;
    }
  }

  .subscript {
    letter-spacing: 2px;
    font-size: variables.$font-xs;
    font-weight: 300;
    color: variables.$blue-dark;
    vertical-align: super;

    @include breakpoints.sm {
      font-size: variables.$font-sm;
    }
  }
}

// Contains all settings for the simulation
section.settings {
  @include breakpoints.container();
  display: flex;
  flex-direction: column;

  @include breakpoints.xl {
    flex-direction: row;
  }

  div.variables {
    flex: 2 1 0;
    display: flex;
    flex-direction: column;

    @include breakpoints.md {
      flex-direction: row;
    }

    div.variable {
      flex: 1 1 0;
      justify-content: space-between;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;

      @include breakpoints.md {
        margin-top: 0px;

        &:first-child {
          padding-right: 1.5rem;
        }

        &:last-child {
          padding-left: 1.5rem;
        }
      }
    }
  }

  .aircon {
    order: -1;
    margin: 1.5rem auto;

    height: 8rem;
    width: 8rem;

    border: 1px solid variables.$grey-light;
    background-color: variables.$grey-extra-light;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out, box-shadow 0.2s ease-in-out;
    outline: none;
    touch-action: manipulation;

    @include breakpoints.xl {
      order: 0;
      margin: auto 1.5rem;
    }

    &:hover {
      background-color: variables.$grey-light;
    }

    &:focus {
      box-shadow: variables.$focus-shadow;
    }

    &:active {
      transition: none;
      transform: scale(0.98);
    }

    .inner {
      background-color: white;
      border-radius: 50%;
      height: 6.5rem;
      width: 6.5rem;
      margin: auto auto;
      transition: background-color 0.1s ease-in-out;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
        height: auto;
        margin-top: 0.5rem;
      }

      span {
        font-size: variables.$font-xs;
        font-weight: 300;
        color: variables.$grey-medium;
        transition: color 0.1s ease-in-out;
      }
    }
  }

  .bordered {
    border-left: none;

    @include breakpoints.xl {
      border-left: 1px solid variables.$grey-medium;
    }
  }

  label {
    font-size: 1.25rem;
    font-weight: 300;
    color: variables.$grey-dark;
    letter-spacing: 2px;
    display: block;
  }

  div.input {
    background-color: variables.$grey-extra-light;
    border: 1px solid variables.$grey-medium;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 5rem;
    outline: 1px solid transparent;
    transition: box-shadow 0.2s ease-in-out;

    &:focus-within {
      box-shadow: variables.$focus-shadow;
    }

    input {
      background-color: transparent;
      border: none;
      outline: none;
      font-size: variables.$font-md;
      width: 100%;
      margin: 1rem 4px;
      text-align: center;
      letter-spacing: 2px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }

    span.units {
      font-size: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin: 1.5rem 8px;
      align-self: flex-end;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        height: 50%;
        display: block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        outline: 1px solid transparent;
        transition: box-shadow 0.2s ease-in-out, outline-color 0.2s ease-in-out;

        // prevent double-tap zoom
        touch-action: manipulation;

        &:hover {
          background-color: variables.$grey-light;
        }

        &:focus {
          box-shadow: variables.$focus-shadow;
        }

        &:active {
          transition: none;
          background-color: variables.$grey-medium;
        }
      }
    }
  }

  .wheels {
    flex: 2 1 0;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    padding-left: 0px;

    @include breakpoints.xl {
      padding-top: 0px;
      margin-top: 0px;
      padding-left: 1.5rem;
    }

    .wheel-options {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-around;
      height: 100%;
      flex-wrap: wrap;

      @include breakpoints.sm {
        flex-wrap: nowrap;
      }
    }

    button {
      background-color: variables.$grey-extra-light;
      border: 1px solid variables.$grey-medium;
      outline: 1px solid transparent;
      border-radius: none;
      font-size: 2rem;
      letter-spacing: 2px;
      width: 100%;
      height: 5rem;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 1rem;
        height: 70%;
        transition: transform 0.2s ease-in-out;

        &.wheel-icon-large {
          height: 85%;
        }

        path {
          transition: color 0.2s ease-in-out;
          color: #ccc;
        }
      }

      &:hover {
        background-color: variables.$grey-light;

        svg {
          transform: scale(1.1);
        }
      }

      &:focus {
        box-shadow: variables.$focus-shadow;
      }

      &:active {
        transition: none;
        background-color: variables.$grey-medium;

        path {
          color: variables.$grey-light;
        }
      }

      &.active {
        border: 1px solid variables.$blue;
      }

      &:last-child {
        margin-top: 1.5rem;
      }

      @include breakpoints.sm {
        &:first-child {
          margin-right: 1.5rem;
        }

        &:last-child {
          margin-left: 1.5rem;
          margin-top: 0;
        }
      }
    }
  }
}
