$font-xl: 4rem;
$font-lg: 2.5rem;
$font-md: 2rem;
$font-sm: 1rem;
$font-xs: 0.75rem;

$grey-extra-light: #f0f2f7;
$grey-light: #e3e8f0;
$grey-medium: #b9bed1;
$grey-dark: #707070;
$grey-extra-dark: #121212;

$blue: #008dff;
$blue-light: #7fc4fd;
$blue-dark: #1876c4;

$teal: #06b6d4;

$focus-shadow: rgba($blue, 0.15) 0px 6px 24px 0px, rgba($blue, 0.5) 0px 0px 0px 1px;
